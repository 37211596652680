img.photo {
  max-width: 15em;
  margin-left: 1em;
  margin-bottom: 1em;
  float: right;
  @media only screen and (max-width: 500px) {
    float: none;
    display: block;
    margin: 0 auto 1em;
  }
}

body,
html {
  font-family: "Fira Sans", sans;
  font-size: 14pt;
  padding: 0;
  margin: 0;
  height: 100%;
  background: #d5d5d5;
  @media only screen and (max-width: 768px) {
    background: #ff6e1a;
  }
}

h1 {
  margin: 0;
}

a {
  color: #ff6e1a;
}

#container {
  max-width: 100em;
  width: 90%;
  margin: 0 auto;
  padding: 2em;
  background: white;
  border: solid #ff6e1a;
  border-width: 0 0.75em;
  min-height: 100%;
  @media only screen and (max-width: 768px) {
    padding: 2%;
    width: 100%;
  }
}

// Fira Sans

@font-face {
  font-family: "Fira Sans";
  src: url("FiraSans-Regular.woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Fira Sans";
  src: url("FiraSans-Italic.woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Fira Sans";
  src: url("FiraSans-Bold.woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Fira Sans";
  src: url("FiraSans-BoldItalic.woff2");
  font-weight: 700;
  font-style: italic;
}
